<template>
    <div>
        <i class="las la-trash i-20 grey" @click="openConfirmation()" />
        <Dialog header="Confirm Delete" :visible.sync="displayConfirmation" :containerStyle="{width: '350px'}" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Do you want to delete this record?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="closeConfirmation()" class="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="closeConfirmation()" class="p-button-text" autofocus />
            </template>
        </Dialog>
    </div>
</template>

<script>
export default {
	data() {
		return {
			displayConfirmation: false,
		}
	},
    methods: {
        openConfirmation() {
            this.displayConfirmation = true;
        },
        closeConfirmation() {
            this.displayConfirmation = false;
        },
    }
}
</script>

<style scoped>
p {
    margin: 0;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>